@import "./styles/googleFonts.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal-content {
  border-radius: 17px !important;
  box-shadow: 1px 2px 20px -10px rgb(0 0 0 / 50%) !important;
}

#feedback-form-wrap .ant-rate {
  font-size: 40px !important;
}
