.brandModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 30px;
  text-align: center;

  .modalTitle {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .modalSelector {
    min-width: 200px;
  }

  .modalConfirm {
    min-width: 150px;
    margin-top: 20px;
  }
}

.templateWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;
  min-height: 100vh;
  background-size: 768px;
}

@media (min-width: 768px) {
  .copyrightStyle {
    padding: 40px 0;
  }
}

.spinnerStyle {
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translate(50%, -50%);
}

.spinnerWrapper{
  min-height: 300px;
}


